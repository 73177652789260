import React, { Fragment } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

const chipBaseStyle = {
  margin: '1px',
  color: 'black',
  border: '1px solid black',
};

const chipColorStyle = {
  TO_BE_DIAGNOSED: {
    ...chipBaseStyle,
    backgroundColor: 'lightgrey',
    fontWeight: 'bold',
  },
  DIAGNOSED_UNKNOWN: {
    ...chipBaseStyle,
    backgroundColor: 'orange',
    fontWeight: 'bold',
  },
  DIAGNOSED_SICK: {
    ...chipBaseStyle,
    backgroundColor: 'red',
    fontWeight: 'bold',
  },
  DIAGNOSED_HEALTHY: {
    ...chipBaseStyle,
    backgroundColor: 'green',
    fontWeight: 'bold',
    color: 'white',
  },
};

const propTypes = {
  result: PropTypes.string,
};

const DiagnosticResultChips = ({ result }) => {
  // Function to determine chip style based on result
  const getChipStyle = (chipResult) => {
    return (
      chipColorStyle[chipResult] || {
        ...chipBaseStyle,
        opacity: 0.2,
        backgroundColor: 'transparent',
      }
    );
  };

  // Determine the label key based on the result
  const getLabelKey = (chipResult) => {
    switch (chipResult) {
      case 'TO_BE_DIAGNOSED':
        return 'kenshin_case_diagnostic_result_to_be_diagnosed';
      case 'DIAGNOSED_UNKNOWN':
        return 'kenshin_case_diagnostic_result_diagnosed_unknown';
      case 'DIAGNOSED_SICK':
        return 'kenshin_case_diagnostic_result_diagnosed_sick';
      case 'DIAGNOSED_HEALTHY':
        return 'kenshin_case_diagnostic_result_diagnosed_healthy';
      default:
        return '';
    }
  };

  return (
    <Chip
      data-testid={`chip-${result}`}
      label={intl.get(getLabelKey(result))}
      style={getChipStyle(result)}
    />
  );
};

DiagnosticResultChips.propTypes = propTypes;

export default DiagnosticResultChips;
