// const getLocalStored = (key) => localStorage.getItem(key);
// const setLocalStored = (key, value) => localStorage.setItem(key, value);
// const removeLocalStored = (key) => localStorage.removeItem(key);
// export default {
//   get: (...args) => getLocalStored(...args),
//   set: (...args) => setLocalStored(...args),
//   remove: (...args) => removeLocalStored(...args),
// };

import { browserStorage } from './storage';

const getLocalStored = (key) => browserStorage.getItem(key);

const setLocalStored = (key, value) => browserStorage.setItem(key, value);

const removeLocalStored = (key) => browserStorage.removeItem(key);

const clearLocalStored = () => browserStorage.clear();

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  get: (...args) => getLocalStored(...args),
  set: (...args) => setLocalStored(...args),
  remove: (...args) => removeLocalStored(...args),
  clear: () => clearLocalStored(),
};
