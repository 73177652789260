// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @mui material components
import List from '@mui/material/List';
import { Collapse, Icon, ListItemButton } from '@mui/material';
import Stack from '@mui/material/Stack';

import { useMaterialUIController } from 'context';
import MDTypography from 'components/MDTypography';
import { sidenavHeader } from 'components/SidenavList/Styles';
import { collapseArrow } from './Styles';
import { Box } from '@material-ui/core';

function SidenavListCollapse({ title, children, open, active, handleClick }) {
  const [controller] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } =
    controller;

  return (
    <List component="nav" data-testid={`sidenav-list-collapse-${title}`}>
      <ListItemButton onClick={handleClick}>
        <Box>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            width="100%">
            <MDTypography
              data-testid={`sidenav-list-collapse-${title}-text`}
              sx={(theme) => ({
                ...sidenavHeader(theme, {
                  transparentSidenav,
                  miniSidenav,
                  whiteSidenav,
                  darkMode,
                }),
                marginRight: '8px',
              })}>
              {title}
            </MDTypography>
            <Icon
              sx={(theme) =>
                collapseArrow(theme, {
                  transparentSidenav,
                  whiteSidenav,
                  miniSidenav,
                  open,
                  active,
                  darkMode,
                })
              }>
              expand_less
            </Icon>
          </Stack>
        </Box>
      </ListItemButton>
      {children && (
        <Collapse in={open} unmountOnExit>
          {children}
        </Collapse>
      )}
    </List>
  );
}

SidenavListCollapse.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  active: PropTypes.bool,
  open: PropTypes.bool,
  handleClick: PropTypes.func,
};

export default SidenavListCollapse;
