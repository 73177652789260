/* eslint-disable prefer-destructuring */

/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
function item(theme, ownerState) {
  const { palette, borders, functions, transitions } = theme;
  const {
    active,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    isClickable,
    customMarginX,
    customPaddingLeft,
  } = ownerState;

  const { transparent, white, grey } = palette;
  const { borderRadius } = borders;
  const { rgba } = functions;

  return {
    pl: customPaddingLeft,
    mx: customMarginX,
    width: 'auto',
    borderRadius: active ? '5px 30px 30px 5px' : borderRadius.md,

    cursor: isClickable ? 'pointer' : 'default',
    backgroundColor: () => {
      let backgroundValue = transparent.main;
      // This place is transparent
      if (
        (active && !transparentSidenav && !whiteSidenav) ||
        (active && transparentSidenav && darkMode)
      ) {
        backgroundValue = rgba(white.main, 0.2);
      } else if (active && transparentSidenav) {
        backgroundValue = grey[300];
      } else if (active && whiteSidenav) {
        backgroundValue = grey[200];
      }
      return backgroundValue;
    },
    '&:hover, &:focus': () => {
      return isClickable
        ? {
            backgroundColor:
              !active &&
              rgba(
                (transparentSidenav && !darkMode) || whiteSidenav
                  ? grey[400]
                  : white.main,
                0.2
              ),
            borderRadius: '5px 30px 30px 5px',
          }
        : null;
    },
  };
}

function itemContent(theme, ownerState) {
  const { palette, typography, transitions, functions } = theme;
  const {
    miniSidenav,
    active,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    nested,
  } = ownerState;

  const { white, dark } = palette;
  const { size, fontWeightRegular, fontWeightLight } = typography;
  const { pxToRem } = functions;

  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '100%',
    padding: `${pxToRem(5)} ${pxToRem(10)}`,
    marginRight: pxToRem(10),
    userSelect: 'none',
    position: 'relative',

    '& span': {
      color:
        ((transparentSidenav && !darkMode) || whiteSidenav) && !active
          ? dark.main
          : white.main,
      fontWeight: nested ? fontWeightRegular : fontWeightLight,
      fontSize: size.sm,
      opacity: miniSidenav ? 0 : 1,
      transition: transitions.create(['opacity', 'color'], {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.standard,
      }),
    },

    '&::before': {
      color:
        ((transparentSidenav && !darkMode) || whiteSidenav) && !active
          ? dark.main
          : white.main,
      fontWeight: nested ? fontWeightRegular : fontWeightLight,
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      left: pxToRem(-15),
      opacity: 1,
      borderRadius: '50%',
      fontSize: size.sm,
    },
  };
}

function itemArrow(theme, ownerState) {
  const { palette, typography, transitions, breakpoints, functions } = theme;
  const {
    noCollapse,
    transparentSidenav,
    whiteSidenav,
    miniSidenav,
    open,
    active,
    darkMode,
  } = ownerState;

  const { white, dark } = palette;
  const { size, fontWeightRegular } = typography;
  const { pxToRem, rgba } = functions;

  let colorValue;
  if (transparentSidenav && darkMode) {
    colorValue = open || active ? white.main : rgba(white.main, 0.25);
  } else if (transparentSidenav || whiteSidenav) {
    colorValue = open || active ? dark.main : rgba(dark.main, 0.25);
  } else {
    colorValue = open || active ? white.main : rgba(white.main, 0.5);
  }

  return {
    fontSize: `${size.lg} !important`,
    fontWeight: fontWeightRegular,
    marginBottom: pxToRem(-1),
    transform: open ? 'rotate(0)' : 'rotate(-180deg)',
    color: colorValue,
    transition: transitions.create(['color', 'transform', 'opacity'], {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.shorter,
    }),

    [breakpoints.up('xs')]: {
      display:
        noCollapse || (transparentSidenav && miniSidenav) || miniSidenav
          ? 'none !important'
          : 'block !important',
    },
  };
}

function itemIcon(theme, ownerState) {
  const { palette, typography, transitions, functions } = theme;
  const { transparentSidenav, whiteSidenav, open, active, darkMode } =
    ownerState;

  const { white, dark } = palette;
  const { size, fontWeightRegular } = typography;
  const { pxToRem, rgba } = functions;

  let colorValue;
  if (transparentSidenav && darkMode) {
    colorValue = open || active ? white.main : rgba(white.main, 0.25);
  } else if (transparentSidenav || whiteSidenav) {
    colorValue = open || active ? dark.main : rgba(dark.main, 0.25);
  } else {
    colorValue = open || active ? white.main : rgba(white.main, 0.5);
  }

  return {
    fontSize: `${size.lg} !important`,
    fontWeight: fontWeightRegular,
    marginRight: pxToRem(10),
    marginBottom: pxToRem(-1),
    color: colorValue,
    transition: transitions.create(['color', 'transform', 'opacity'], {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.shorter,
    }),
  };
}

export { item, itemContent, itemArrow, itemIcon };
