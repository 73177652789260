import React, { Fragment, useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import CloseIcon from '@mui/icons-material/Close';
import { useStyles } from './styles';
import { Grid, IconButton } from '@mui/material';

import localData from 'shared/utils/localData';
import api from 'shared/utils/api';

import Editor from './Editor';
import Preview from './Preview';
import { PageLoader, PageError } from 'components';
import { DOLPHIN_API } from 'shared/constants/apis';
import { USER_DATA } from 'shared/constants/users';

const propTypes = {
  selectedStartDate: PropTypes.any.isRequired,
  selectedEndDate: PropTypes.any.isRequired,
  billData: PropTypes.any.isRequired,
  billDataKenshin: PropTypes.any.isRequired,
  invoiceToOrgId: PropTypes.number.isRequired,
  invoiceToOrgName: PropTypes.string.isRequired,
  invoiceTotalAmount: PropTypes.number.isRequired,
  modalClose: PropTypes.func.isRequired,
};

const PreviewMemo = React.memo(Preview);

// const defaultInvoiceContent = {
//   invoiceDate: '',
//   invoiceTo: 'いしだクリニック 御中',
//   invoiceFrom:
//     '特定非営利活動法人エキスパートイメージング\nアンドインターベンショナルサポート\n理事 中島康雄\nEIIS事務局\n〒222-0033\n横浜市港北区新横浜2-6-16-7F\nTel & Fax  045-534-6115\n',
//   invoiceGreetings: intl.get('billing_invoice_text_greetings'),
//   invoiceDetailedTitle: '遠隔読影支援費用（2024年1月分）',
//   invoiceTotalAmount: 15000,
//   invoiceDetailedAmount: '',
//   invoicePaymentMethod:
//     '特定非営利活動法人エキスパートイメージングアンドインターベンショナルサポート',
//   invoiceRemarks: '※支払期限: 2024年 2月末日',
// };

const getInitInvoiceContent = (
  selectedStartDate,
  selectedEndDate,
  billData,
  billDataKenshin,
  invoiceToOrgId,
  invoiceToOrgName,
  invoiceFromOrgInfo,
  invoiceTotalAmount
) => {
  let initInvoiceContent = {
    invoiceDate: '',
    invoiceTo: '',
    invoiceTo2: '',
    invoiceFrom: '',
    invoiceGreetings: '',
    invoiceDetailedTitle: '',
    invoiceTotalAmount: 0,
    invoiceTaxRate: 10,
    invoiceTotalAmountIncludingTax: 0,
    invoiceDetailedAmount: '',
    invoicePaymentMethod: '',
    invoiceRemarks: '',
  };

  // 1. invoiceData: today
  const today = new Date();
  initInvoiceContent.invoiceDate =
    today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

  // 2. invoiceTo
  if (localData.get('userPreferredLanguage') === 'ja-JP') {
    initInvoiceContent.invoiceTo =
      invoiceToOrgName + intl.get('billing_invoice_text_dear');
  } else {
    initInvoiceContent.invoiceTo =
      intl.get('billing_invoice_text_dear') + invoiceToOrgName;
  }

  // 2. invoiceTo2 (for )

  // 3. invoiceFrom
  initInvoiceContent.invoiceFrom =
    invoiceFromOrgInfo.organizationName +
    '\n' +
    invoiceFromOrgInfo.contact.name +
    '\n' +
    '〒' +
    invoiceFromOrgInfo.contact.zipCode +
    '\n' +
    invoiceFromOrgInfo.contact.addressLine1 +
    invoiceFromOrgInfo.contact.addressLine2 +
    '\n' +
    'TEL: ' +
    invoiceFromOrgInfo.contact.tel +
    '\n' +
    'FAX: ' +
    invoiceFromOrgInfo.contact.fax;
  // 4. invoiceGreetings
  initInvoiceContent.invoiceGreetings = intl.get(
    'billing_invoice_text_greetings'
  );

  // 5. invoiceDetailedTitle
  const startDateString =
    selectedStartDate.getFullYear() +
    '-' +
    (selectedStartDate.getMonth() + 1) +
    '-' +
    selectedStartDate.getDate();
  const endDateString =
    selectedEndDate.getFullYear() +
    '-' +
    (selectedEndDate.getMonth() + 1) +
    '-' +
    selectedEndDate.getDate();
  initInvoiceContent.invoiceDetailedTitle =
    intl.get('billing_invoice_text_detailed_title_default') +
    ' (' +
    startDateString +
    ' ~ ' +
    endDateString +
    ')';

  // 6. invoiceTotalAmount
  // 6.1 total amount
  initInvoiceContent.invoiceTotalAmount = invoiceTotalAmount;

  // 6.2 total amount including tax
  initInvoiceContent.invoiceTotalAmountIncludingTax =
    (invoiceTotalAmount * (100 + initInvoiceContent.invoiceTaxRate)) / 100;

  // 7. invoiceDetailedAmount
  // 7.1 Get bill data detailed amount
  const selectedBillData = billData.filter(
    (bill) => bill.srcOrgId === invoiceToOrgId
  );

  const mapForBillData = new Map();
  let adjustmentAmount = {
    count: 0,
    totalAmount: 0,
  };
  selectedBillData.forEach((bill, index) => {
    const tagIds = bill.tagIds;
    const tags = JSON.parse(bill.tags);
    const changeHistory = JSON.parse(bill.changeHistory);

    // for special rules
    tagIds.forEach((tagId) => {
      // extract ...
      const tag = tags.filter((tag) => tag.id === tagId)[0];
      const changeForThisTag = changeHistory.filter(
        (history) => history.tagId === tagId && history.type === 'rule'
      );
      let changeFromRule = {};
      changeForThisTag.forEach((change) => {
        changeFromRule[change.ruleIndex] = {
          count: 1,
          feeChange: change.feeChange,
          remark: change.remark,
        };
      });
      const statics = mapForBillData.get(tagId);
      if (!statics) {
        const newStatics = {
          count: 1,
          labelText: tag.labelText,
          defaultFee: tag.defaultFee,
          changeFromRule: changeFromRule,
        };
        mapForBillData.set(tagId, newStatics);
      } else {
        const oldChangeFromRule = statics.changeFromRule;
        let newChangeFromRule = {
          ...oldChangeFromRule,
        };
        Object.keys(changeFromRule).forEach((key) => {
          if (newChangeFromRule[key]) {
            newChangeFromRule[key] = {
              count: oldChangeFromRule[key].count + 1,
              feeChange: oldChangeFromRule[key].feeChange,
              remark: oldChangeFromRule[key].remark,
            };
          } else {
            newChangeFromRule[key] = {
              ...changeFromRule[key],
            };
          }
        });
        const newStatics = {
          count: statics.count + 1,
          labelText: statics.labelText,
          defaultFee: statics.defaultFee,
          changeFromRule: newChangeFromRule,
        };
        mapForBillData.set(tagId, newStatics);
      }
    });

    // for adjustment by user
    const changeFromAdjustment = changeHistory.filter(
      (history) => history.type === 'adjust'
    );
    changeFromAdjustment.forEach((change) => {
      adjustmentAmount.count += 1;
      adjustmentAmount.totalAmount += change.feeChange;
    });
  });
  // 7.2 Get kenshin bill data detailed amount
  const selectedBillDataKenshin = billDataKenshin.filter(
    (bill) => bill.srcOrgId === invoiceToOrgId
  );

  const mapForBillDataKenshin = new Map();
  let adjustmentAmountKenshin = {
    count: 0,
    totalAmount: 0,
  };
  selectedBillDataKenshin.forEach((bill, index) => {
    if (bill.tagCount === null) return;
    const tagCount = JSON.parse(bill.tagCount);
    const changeHistory = JSON.parse(bill.changeHistory);

    for (let key in tagCount) {
      const tagId = parseInt(key);
      const tag = tagCount[key];
      const statics = mapForBillDataKenshin.get(tagId);
      if (!statics) {
        mapForBillDataKenshin.set(tagId, tag);
      } else {
        const newStatics = {
          count: statics.count + tag.count,
          fee: statics.fee,
          labelText: statics.labelText,
        };
        mapForBillDataKenshin.set(tagId, newStatics);
      }
    }

    // for adjust by user
    const changeFromAdjustment = changeHistory.filter(
      (history) => history.type === 'adjust'
    );
    changeFromAdjustment.forEach((change) => {
      adjustmentAmountKenshin.count += 1;
      adjustmentAmountKenshin.totalAmount += change.feeChange;
    });
  });

  initInvoiceContent.invoiceDetailedAmount = {
    mapForBillData: mapForBillData,
    adjustmentAmount: adjustmentAmount,
    mapForBillDataKenshin: mapForBillDataKenshin,
    adjustmentAmountKenshin: adjustmentAmountKenshin,
  };

  // 8. invoicePaymentMethod
  initInvoiceContent.invoicePaymentMethod =
    invoiceFromOrgInfo.paymentMethod.bank +
    ' ' +
    invoiceFromOrgInfo.paymentMethod.branch +
    '\n' +
    invoiceFromOrgInfo.paymentMethod.accountType +
    ' ' +
    invoiceFromOrgInfo.paymentMethod.accountNo +
    '\n' +
    invoiceFromOrgInfo.paymentMethod.accountName;

  // 9. invoiceRemarks
  initInvoiceContent.invoiceRemarks = '';

  return initInvoiceContent;
};

const DashboardBillingReceivedForReceviedTasksSummaryTableInvoceEditor = ({
  selectedStartDate,
  selectedEndDate,
  billData,
  billDataKenshin,
  invoiceToOrgId,
  invoiceToOrgName,
  invoiceTotalAmount,
  modalClose,
}) => {
  const classes = useStyles();
  //TODO: Generate the init invoice content

  const [loaded, setLoaded] = useState(false);
  const [invoiceContent, setInvoiceContent] = useState({});
  const [previewContent, setPreviewContent] = useState({});

  const organizationId = localData.get(USER_DATA.CURRENT_ORGANIZATION_ID);

  useEffect(() => {
    setLoaded(false);

    const apiVariables = {
      params: {
        organizationId: parseInt(organizationId),
      },
    };

    api
      .get(DOLPHIN_API.ORGANIZATION_PREFERENCE, apiVariables)
      .then(async (data) => {
        // TODO: generate init invoice content
        const invoiceFromOrgInfo = data;
        const initInvoiceContent = getInitInvoiceContent(
          selectedStartDate,
          selectedEndDate,
          billData,
          billDataKenshin,
          invoiceToOrgId,
          invoiceToOrgName,
          invoiceFromOrgInfo,
          invoiceTotalAmount
        );
        setInvoiceContent(initInvoiceContent);
        setPreviewContent(initInvoiceContent);
        setLoaded(true);
      })
      .catch((error) => {
        return <PageError />;
      });
  }, [
    billData,
    billDataKenshin,
    invoiceToOrgId,
    invoiceToOrgName,
    invoiceTotalAmount,
    organizationId,
    selectedEndDate,
    selectedStartDate,
  ]);

  if (!loaded) {
    return <PageLoader />;
  }

  return (
    <Fragment>
      <Grid>
        <Grid
          container
          className={classes.actionGrid}
          justifyContent="flex-end">
          <div>
            <IconButton onClick={modalClose}>
              <CloseIcon sx={{ color: '#fff' }} />
            </IconButton>
          </div>
        </Grid>
        <Grid
          container
          className={classes.invoiceEditorGrid}
          columns={{ xs: 12 }}>
          <Grid item className={classes.editorGrid} xs={6}>
            <Editor
              invoiceContent={invoiceContent}
              setInvoiceContent={setInvoiceContent}
              setPreviewContent={setPreviewContent}
            />
          </Grid>
          <Grid item className={classes.previewGrid} xs={6}>
            <PreviewMemo invoiceContent={previewContent} />
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

DashboardBillingReceivedForReceviedTasksSummaryTableInvoceEditor.propTypes =
  propTypes;

export default withRouter(
  DashboardBillingReceivedForReceviedTasksSummaryTableInvoceEditor
);
