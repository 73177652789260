import pxToRem from 'assets/theme/functions/pxToRem';

/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
function navbar(theme, ownerState) {
  const { palette, functions, transitions, breakpoints } = theme;
  const { transparentNavbar, absolute } = ownerState;

  const { white, topbar } = palette;
  const { pxToRem } = functions;

  return {
    boxShadow: `0 2px 0px 0px #373635`,
    backdropFilter:
      transparentNavbar || absolute
        ? 'none'
        : `saturate(200%) blur(${pxToRem(30)})`,
    backgroundColor: topbar.primary,

    color: white.main,
    minHeight: pxToRem(55),
    display: 'grid',
    alignItems: 'center',
    height: pxToRem(55),
    paddingTop: pxToRem(0),
    paddingBottom: pxToRem(0),
    paddingRight: absolute ? pxToRem(8) : 0,
    paddingLeft: absolute ? pxToRem(16) : 0,

    '& > *': {
      transition: transitions.create('all', {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.standard,
      }),
    },

    '& .MuiToolbar-root': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',

      [breakpoints.up('sm')]: {
        minHeight: 'auto',
        padding: `${pxToRem(4)} ${pxToRem(16)}`,
      },
    },
  };
}

const navbarContainer = ({ breakpoints }) => ({
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  width: '100vw',
  maxWidth: '100vw',

  [breakpoints.up('md')]: {
    alignItems: 'center',
    paddingTop: '0',
    paddingBottom: '0',
  },
});

const navbarRow = ({ breakpoints }, { isMini }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  // width: '100%',

  [breakpoints.up('md')]: {
    justifyContent: isMini ? 'space-between' : 'stretch',
    width: isMini ? '100%' : 'max-content',
  },

  [breakpoints.up('xl')]: {
    justifyContent: 'stretch !important',
    width: 'max-content !important',
  },
});

const navbarIconButton = ({ typography: { size }, breakpoints }) => ({
  px: 1,
  '& .material-icons, .material-icons-round': {
    fontSize: `${size.md}`,
    marginLeft: pxToRem(10),
    marginRight: pxToRem(15),
  },

  '& .MuiTypography-root': {
    display: 'none',
    [breakpoints.up('sm')]: {
      display: 'inline-block',
      lineHeight: 1.2,
      ml: 0.5,
    },
  },
});

const navbarDesktopMenu = ({ breakpoints }) => ({
  display: 'none !important',
  cursor: 'pointer',

  [breakpoints.up('xl')]: {
    display: 'inline-block !important',
  },
});

const navbarMobileMenu = ({ breakpoints }) => ({
  display: 'inline-block',
  lineHeight: 0,

  [breakpoints.up('xl')]: {
    display: 'none',
  },
});

export {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMobileMenu,
};
