import PropTypes from 'prop-types';

// @mui material components
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

function UserNameBox({ name }) {
  return (
    <Tooltip title={name} arrow>
      <Box
        sx={{
          maxWidth: '150px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          paddingLeft: '10px',
        }}>
        <Typography
          variant="body2"
          sx={{
            color: 'white !important',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontSize: '0.875rem',
          }}>
          {name}
        </Typography>
      </Box>
    </Tooltip>
  );
}

// Typechecking props for the UserNameBox
UserNameBox.propTypes = {
  name: PropTypes.string.isRequired,
};

export default UserNameBox;
