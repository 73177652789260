/* eslint react/prop-types: 0 */
import React, {
  useState,
  useMemo,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { MRT_Localization_JA } from 'material-react-table/locales/ja';
import { MRT_Localization_EN } from 'material-react-table/locales/en';

// Material UI Imports
import { Button, useTheme, createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { jaJP } from '@mui/material/locale';

import intl from 'react-intl-universal';
import PropTypes from 'prop-types';

// Icon Imports
import DiagnosticResultChips from './DiagnosticResult';
import SingleChip from './DiagnosticResult/ResultChip';

// local library
import { getSelectedAfterFiltering } from './utils';
//
import localData from 'shared/utils/localData';

const propTypes = {
  assignments: PropTypes.array.isRequired,
  updateLocalAssignments: PropTypes.func.isRequired,
  selectedIndice: PropTypes.array.isRequired,
  setSelectedIndice: PropTypes.func.isRequired,
  handleOpenViewer: PropTypes.func.isRequired,
  tablePageIndex: PropTypes.number.isRequired,
  tablePageSize: PropTypes.number.isRequired,
  tableColumnFilters: PropTypes.array.isRequired,
};

const Table = (
  {
    assignments,
    updateLocalAssignments,
    selectedIndice,
    setSelectedIndice,
    handleOpenViewer,
    tablePageIndex,
    tablePageSize,
    tableColumnFilters,
  },
  ref
) => {
  const theme = useTheme();

  const [pagination, setPagination] = useState({
    pageIndex: tablePageIndex,
    pageSize: tablePageSize,
  });
  const [columnFilters, setColumnFilters] = useState(tableColumnFilters);
  const [data, setData] = useState([]);

  useImperativeHandle(ref, () => ({
    pagination,
    setPagination,
    columnFilters,
    setColumnFilters,
  }));

  useEffect(() => {
    const { newSelectedIndice } = getSelectedAfterFiltering(
      assignments,
      columnFilters
    );
    const data_ = selectedIndice.map((index) => {
      const assignment = assignments[index];
      return {
        caseIdentifierId: assignment.caseIdentifierId,
        srcOrgName: assignment.srcOrgName,
        patientId: assignment.patientId,
        studyInstanceUID: assignment.studyInstanceUID,
        bodypart: assignment.bodypart,
        modality: assignment.modality,
        diagnosis: assignment.diagnosis,
        id: assignment.id,
        viewerInfo: {
          caseId: assignment.id,
          index: index,
        },
      };
    });
    setData(data_);
    setSelectedIndice(newSelectedIndice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnFilters]);

  useEffect(() => {
    const data_ = selectedIndice.map((index) => {
      const assignment = assignments[index];
      return {
        caseIdentifierId: assignment.caseIdentifierId,
        srcOrgName: assignment.srcOrgName,
        patientId: assignment.patientId,
        studyInstanceUID: assignment.studyInstanceUID,
        bodypart: assignment.bodypart,
        modality: assignment.modality,
        diagnosis: assignment.diagnosis,
        id: assignment.id,
        viewerInfo: {
          caseId: assignment.id,
          index: index,
        },
      };
    });
    setData(data_);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndice, setData]);

  useEffect(() => {
    const data_ = selectedIndice.map((index) => {
      const assignment = assignments[index];
      return {
        caseIdentifierId: assignment.caseIdentifierId,
        srcOrgName: assignment.srcOrgName,
        patientId: assignment.patientId,
        studyInstanceUID: assignment.studyInstanceUID,
        bodypart: assignment.bodypart,
        modality: assignment.modality,
        diagnosis: assignment.diagnosis,
        id: assignment.id,
        viewerInfo: {
          caseId: assignment.id,
          index: index,
        },
      };
    });
    setData(data_);
  }, [assignments, selectedIndice]);

  const columns = useMemo(
    () => [
      {
        header: intl.get('kenshin_case_table_header_source_organization'),
        accessorKey: 'srcOrgName',
        size: 50,
        enableColumnActions: false,
        enableColumnFilter: true,
      },
      {
        header: 'Case ID',
        accessorKey: 'caseIdentifierId',
        size: 50,
        enableColumnActions: false,
        enableColumnFilter: true,
      },
      {
        header: intl.get('kenshin_case_table_header_patient_id'),
        accessorKey: 'patientId',
        size: 50,
        enableColumnActions: false,
        enableColumnFilter: true,
      },

      {
        header: 'Study Instance UID',
        accessorKey: 'studyInstanceUID',
        size: 50,
        enableColumnActions: false,
        enableColumnFilter: true,
      },
      {
        header: intl.get('kenshin_case_table_header_body_part'),
        accessorKey: 'bodypart',
        size: 50,
        enableColumnActions: false,
        enableColumnFilter: true,
      },
      {
        header: 'Modality',
        accessorKey: 'modality',
        size: 50,
        enableColumnActions: false,
        enableColumnFilter: true,
      },
      {
        header: intl.get('kenshin_case_table_header_diagnostic_result'),
        accessorKey: 'diagnosis',
        size: 50,
        enableColumnActions: false,
        enableColumnFilter: true,
        filterVariant: 'select',
        filterSelectOptions: [
          {
            label: intl.get('kenshin_case_diagnostic_result_to_be_diagnosed'),
            value: 'TO_BE_DIAGNOSED',
          },
          {
            label: intl.get('kenshin_case_diagnostic_result_diagnosed_healthy'),
            value: 'DIAGNOSED_HEALTHY',
          },
          {
            label: intl.get('kenshin_case_diagnostic_result_diagnosed_sick'),
            value: 'DIAGNOSED_SICK',
          },
          {
            label: intl.get('kenshin_case_diagnostic_result_diagnosed_unknown'),
            value: 'DIAGNOSED_UNKNOWN',
          },
        ],
        filterFn: (row, id, filterValue) => {
          return row.getValue(id) === filterValue;
        },
        Cell: ({ cell }) => {
          return <SingleChip result={cell.getValue()} />;
        },
      },
      {
        header: intl.get('kenshin_case_table_header_viewer'),
        accessorKey: 'viewerInfo',
        size: 50,
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: ({ cell }) => {
          const viewerInfo = cell.getValue();
          const caseId = viewerInfo.caseId;
          const index = viewerInfo.index;
          return (
            <Button
              variant="contained"
              color="primary"
              style={{ color: 'white' }}
              onClick={() => {
                handleOpenViewer(caseId, index);
              }}>
              {intl.get('case_list_button_view')}
            </Button>
          );
        },
      },
    ],
    []
  );

  const preferredLanguage = localData.get('userPreferredLanguage');
  let MRT_Localization = null;
  if (preferredLanguage === 'ja-JP') {
    MRT_Localization = MRT_Localization_JA;
  } else {
    MRT_Localization = MRT_Localization_EN;
  }

  const table = useMaterialReactTable({
    columns,
    data,
    localization: {
      ...MRT_Localization,
      toggleSelectAll: intl.get('share_mrt2_text_label_body_toggle_select_all'),
      noRecordsToDisplay: intl.get('share_muitable_text_label_body_no_match'),
    },
    enableStickyHeader: true,
    enableRowActions: false,
    state: {
      columnFilters,
      density: 'compact',
      showColumnFilters: true,
      pagination,
    },
    manualFiltering: true,
    onColumnFiltersChange: setColumnFilters,
    onPaginationChange: setPagination,
    autoResetPageIndex: false,
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    muiSearchTextFieldProps: {
      size: 'small',
      variant: 'outlined',
    },
    muiPaginationProps: {
      color: 'secondary',
      rowsPerPageOptions: [10, 20, 50, 100],
      shape: 'rounded',
      variant: 'outlined',
    },
  });

  return (
    <ThemeProvider theme={createTheme(theme, jaJP)}>
      <MaterialReactTable id="AssignmentKenshin" table={table} />
    </ThemeProvider>
  );
};

Table.propTypes = propTypes;

const ForwardedTable = forwardRef(Table);

export default ForwardedTable;
