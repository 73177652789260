import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import intl from 'react-intl-universal';
import Grid from '@mui/material/Grid';
import { Button } from '@material-ui/core';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import Modal from 'components/Modal';
import MDTypography from 'components/MDTypography';
import FormField from 'pages/parts/FormField';
import api from 'shared/utils/api';
import useStyles from './styles';
import { ConfirmModal } from 'shared/modals/confirmModal';
import { OrganizationProptypes } from 'shared/propTypes/organizationType';
import { UserType } from 'shared/constants/users';
import { DOLPHIN_API } from 'shared/constants/apis';

import MUIDataTable from 'mui-datatables';
import { useMaterialUIController } from 'context';
import { ThemeProvider } from '@mui/material/styles';
import { getTablesTheme } from 'pages/parts/CustomThemes';
import Privilege from './Privilege';
import { modalWidths } from 'shared/utils/styles';

const propTypes = {
  // organizationInfo: PropTypes.shape(OrganizationProptypes),
  organizationInfo: PropTypes.object,
  fetchOrganizationInfo: PropTypes.func,
};

const OPERATION_TYPE = {
  ADD: 'add',
  REMOVE: 'remove',
};

const USER_TYPE_TABS = {
  STAFF: 0,
  DOCTOR: 1,
};

const DashboardOrganizationSettingsUserManagement = ({
  organizationInfo,
  fetchOrganizationInfo,
}) => {
  const classes = useStyles();

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const tabStyle = {
    color: darkMode ? 'white' : 'grey',
    maxWidth: '100%',
  };

  // const [showConfirmBeforeSend, setShowConfirmBeforeSend] = useState(false);
  // const [formState, setFormState] = useState({
  //   staffUsers: organizationInfo.users.filter(
  //     (user) => user.userType === UserType.STAFF
  //   ),
  //   doctorUsers: organizationInfo.users.filter(
  //     (user) => user.userType === UserType.DOCTOR
  //   ),
  // });
  // const [newStaffUser, setNewStaffUser] = useState('');
  // const [newDoctorUser, setNewDoctorUser] = useState('');
  // const [operationData, setOperationData] = useState({
  //   operationType: '',
  //   userType: '',
  //   username: '',
  // });

  const [privilegeDetailsModalOpen, setPrivilegeDetailsModalOpen] =
    useState(false);
  const [privilegeDetails, setPrivilegeDetails] = useState({});

  // useEffect(() => {
  //   setFormState({
  //     staffUsers: organizationInfo.users.filter(
  //       (user) => user.userType === UserType.STAFF
  //     ),
  //     doctorUsers: organizationInfo.users.filter(
  //       (user) => user.userType === UserType.DOCTOR
  //     ),
  //   });
  // }, [organizationInfo]);

  // const handleRemoveClick = (user) => {
  //   setOperationData({
  //     operationType: OPERATION_TYPE.REMOVE,
  //     userType: user.userType,
  //     username: user.username,
  //   });

  //   setShowConfirmBeforeSend(true);
  // };

  // const handleAddClick = (user) => {
  //   setOperationData({
  //     operationType: OPERATION_TYPE.ADD,
  //     userType: user,
  //     username: user === UserType.STAFF ? newStaffUser : newDoctorUser,
  //   });
  //   setShowConfirmBeforeSend(true);
  // };

  // const generateConfirmWindowContents = () => {
  //   let contents = '';
  //   if (operationData.operationType === OPERATION_TYPE.REMOVE) {
  //     contents =
  //       operationData.userType === UserType.STAFF
  //         ? ` ${intl.get(
  //             'organization_settings_user_management_modal_text_confirm_remove_staff'
  //           )} ${operationData.username}`
  //         : ` ${intl.get(
  //             'organization_settings_user_management_modal_text_confirm_remove_doctor'
  //           )} ${operationData.username}`;
  //   } else if (operationData.operationType === OPERATION_TYPE.ADD) {
  //     contents =
  //       operationData.userType === UserType.STAFF
  //         ? ` ${intl.get(
  //             'organization_settings_user_management_modal_text_confirm_add_staff'
  //           )} ${operationData.username}`
  //         : ` ${intl.get(
  //             'organization_settings_user_management_modal_text_confirm_add_doctor'
  //           )} ${operationData.username}`;
  //   }
  //   return (
  //     <Grid
  //       spacing={3}
  //       container
  //       style={{ marginTop: '15px', marginLeft: '8px' }}>
  //       <Grid columns={{ xs: 12 }}>
  //         <MDTypography>{contents}</MDTypography>
  //       </Grid>
  //     </Grid>
  //   );
  // };

  // const handleConfirmClick = async () => {
  //   setShowConfirmBeforeSend(true);
  //   await sendToServer();
  //   setShowConfirmBeforeSend(false);
  // };

  // const sendToServer = async () => {
  //   api
  //     .put(DOLPHIN_API.USER_MANAGEMENT, {
  //       params: {
  //         organizationId: organizationInfo.id,
  //       },
  //       data: {
  //         ...operationData,
  //       },
  //     })
  //     .then(
  //       () => {
  //         fetchOrganizationInfo();
  //       },
  //       (error) => {
  //         console.error(error);
  //         alert('Failed');
  //       }
  //     );
  // };

  const handleUpdatePrivilege = async (employmentId, newPrivilege) => {
    api
      .put(DOLPHIN_API.EMPLOYMENT_PRIVILEGE, {
        params: {
          organizationId: organizationInfo.id,
        },
        data: {
          employmentId: employmentId,
          newPrivilege: newPrivilege,
        },
      })
      .then(
        (res) => {
          setPrivilegeDetailsModalOpen(false);
          setPrivilegeDetails({});
          fetchOrganizationInfo();
        },
        (error) => {
          console.error(error);
          alert('Failed in updating privilege');
        }
      );
  };

  const users = organizationInfo.users.sort(
    (user1, user2) => user1.id - user2.id
  );

  const employments = organizationInfo.employments.sort(
    (employment1, employment2) => employment1.userId - employment2.userId
  );

  if (users.length !== employments.length) {
    console.log('ALERT');
  }

  // Construct table data for representing the employments,
  // Columns includs
  // username, userFullName, email
  let tableData = [];
  for (let i = 0; i < users.length; i++) {
    const user = users[i];
    const employment = employments[i];
    const rowData = [
      user.username,
      user.firstName + ' ' + user.lastName,
      user.email,
      { employment, user },
    ];
    tableData.push(rowData);
  }

  const columns = [
    {
      name: 'username',
      label: intl.get('organization_settings_user_management_label_username'),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'userFullName',
      label: intl.get(
        'organization_settings_user_management_label_user_full_name'
      ),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'privilege',
      label: 'Privilege',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setPrivilegeDetails(value);
                  setPrivilegeDetailsModalOpen(true);
                }}>
                Details
              </Button>
            </div>
          );
        },
        filter: false,
        sort: false,
      },
    },
  ];

  const options = {
    print: false,
    download: false,
    selectableRowsHideCheckboxes: false,
    selectableRows: 'single',
    rowsPerPageOptions: [10, 20, 50],
    onRowsDelete: (rowsDeleted) => {
      // if (value === USER_TYPE_TABS.STAFF) {
      //   let selectedStaff = formState.staffUsers[rowsDeleted.data[0].dataIndex];
      //   handleRemoveClick(selectedStaff);
      // } else {
      //   let selectedDoctor =
      //     formState.doctorUsers[rowsDeleted.data[0].dataIndex];
      //   handleRemoveClick(selectedDoctor);
      // }
    },
    textLabels: {
      body: {
        noMatch: intl.get('share_muitable_text_label_body_no_match'),
        toolTip: intl.get('share_muitable_text_label_body_tool_tip'),
      },
      pagination: {
        next: intl.get('share_muitable_text_label_pagination_next'),
        previous: intl.get('share_muitable_text_label_pagination_previous'),
        rowsPerPage: intl.get(
          'share_muitable_text_label_pagination_rows_per_page'
        ),
        displayRows: intl.get(
          'share_muitable_text_label_pagination_display_rows'
        ),
      },
      toolbar: {
        search: intl.get('share_muitable_text_label_toolbar_search'),
        downloadCsv: intl.get('share_muitable_text_label_toolbar_download_csv'),
        print: intl.get('share_muitable_text_label_toolbar_print'),
        viewColumns: intl.get('share_muitable_text_label_toolbar_view_columns'),
        filterTable: intl.get('share_muitable_text_label_toolbar_filter_table'),
      },
      filter: {
        all: intl.get('share_muitable_text_label_filter_all'),
        title: intl.get('share_muitable_text_label_filter_title'),
        reset: intl.get('share_muitable_text_label_filter_reset'),
      },
      viewColumns: {
        title: intl.get('share_muitable_text_label_view_columns_title'),
        titleAria: intl.get(
          'share_muitable_text_label_view_columns_title_aria'
        ),
      },
      selectedRows: {
        text: intl.get('share_muitable_text_label_selected_rows_text'),
        delete: intl.get('share_muitable_text_label_selected_rows_delete'),
        deleteAria: intl.get(
          'share_muitable_text_label_selected_rows_delete_aria'
        ),
      },
    },
  };

  return (
    <MDBox>
      <Grid
        className={classes.block}
        spacing={3}
        container
        columns={{ xs: 12 }}>
        <Grid item xs={12}>
          <MDBox sx={{ marginTop: 1, marginBottom: 2 }}>
            <ThemeProvider theme={getTablesTheme(darkMode)}>
              <MUIDataTable
                options={options}
                title={intl.get(
                  'organization_settings_user_management_text_staffs'
                )}
                columns={columns}
                data={tableData}
              />
            </ThemeProvider>
          </MDBox>
        </Grid>
      </Grid>

      {privilegeDetailsModalOpen && (
        <Modal
          isOpen
          testId="modal:admin-org-details"
          width={modalWidths.sm}
          withCloseIcon={true}
          onClose={() => setPrivilegeDetailsModalOpen(false)}
          renderContent={() => (
            <Privilege
              privilegeDetails={privilegeDetails}
              close={() => {
                setPrivilegeDetailsModalOpen(false);
              }}
              updatePrivilege={handleUpdatePrivilege}
            />
          )}
        />
      )}
    </MDBox>
  );
};

DashboardOrganizationSettingsUserManagement.propTypes = propTypes;

export default withRouter(DashboardOrganizationSettingsUserManagement);
