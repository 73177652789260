function cardItem(theme, ownerState) {
  const { palette } = theme;
  const { darkMode } = ownerState;

  const { topbar } = palette;

  const mainColor = darkMode ? palette.white.main : topbar.primary;

  return {
    padding: '2em 3em 2em',
    overflow: 'visible',
    border: `3px solid ${mainColor}`,
    alignItems: 'center',
    backgroundColor: darkMode ? topbar.primary : palette.white.main,
    '&:hover': {
      backgroundColor: mainColor,
    },
  };
}

function cardText(theme, ownerState) {
  const { palette } = theme;
  const { darkMode, isHovered } = ownerState;

  return {
    color: darkMode
      ? isHovered
        ? palette.topbar.primary
        : palette.white.main
      : isHovered
      ? palette.white.main
      : palette.topbar.primary,
  };
}

export { cardItem, cardText };
